import React,{useEffect, useState, useCallback} from 'react'
import {useNavigate, useParams, Link } from 'react-router-dom'
import DataTable from 'react-data-table-component';
import Header from '../components/nav/Header';
import BottomMenu from '../components/nav/BottomMenu';
import { useLiveQuery } from 'dexie-react-hooks'
import { db } from '../db/db'

import { stock } from '../components/otros/textos';

const Stock = () => {
    

  const guias = useLiveQuery(
    () => db.guiaDespacho.toArray()
  );

  const data = guias && guias.map( c => {
      return {guia:c.id, rut:c.guia.rut, completed: c.guia.completed}
  })



  return (
    <>
        <Header />
    
        <div className='container p-4'>
            <h6 >
            <i class="fa-solid fa-sheet-plastic"></i> {stock.titulo}
            </h6>
            <table>
              <thead>
                <tr>
                  <th>{stock.columna1}</th>
                  <th>{stock.columna2}</th>
                </tr>
              </thead>
              <tbody>
                {data && data.length > 0 ? (
                    data.map((row) => (
                      <tr key={row.id}>
                        <td>{row.rut}</td>
                        {row.completed === 1 ? <td ><Link style={{color:'green'}} to={`/stock/${row.guia}`}>{row.guia}</Link></td> : <td style={{color:'red'}}>{row.guia}</td>}
                      </tr>
                    ))
                ):(
                    <p>No hay guías en Stock</p>
                )}
                
              </tbody>
            </table>
        </div>
        <BottomMenu/>
    </>
  )
}

export default Stock