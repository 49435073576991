export const multiX = {
  id: 2,
  domain: "mlog.multi-xsalmon.com",
  //domain: "multix-qa.patagonia.cloud",
  //key: "8d368a8b-4bd2-4d88-9e82-ac567a3fc6a0",
  key: "8d368a8b-4bd2-4d88-9e82-ac567a3fc6a0",
  rut: "798911600",
  sociedad: "Multi X",
  tipo: "MULTIX PROD",
  //tipo: "MULTIX QA",
};

export const version = "2.2.4";
export const fechaUltAct = "24/10/2024";
