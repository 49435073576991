
// funciones para la lectura y generación de codigos QR
import { obtenerCoordenadas } from './gpsFunctions';
import { inflateRaw  } from 'pako'
import { toast } from 'react-toastify'
import { db } from '../db/db'
import { useNavigate } from 'react-router-dom'
import pako from 'pako'
import { decompressFromBase64 } from 'lz-string';


function decryptData(str){

    try {
        var b64DataNoSpaces = str.replace(/ /g,"+");
        var strData= atob(b64DataNoSpaces);
        var charData= strData.split('').map(function(x){return x.charCodeAt(0);});
        var binData= new Uint8Array(charData);
        var data = inflateRaw(binData);
        return String.fromCharCode.apply(null, new Uint16Array(data));
        
    } catch(exception) {

        toast.error('Codigo Qr de guia con errores');

        try {
            // $jsonData = strtr($jsonString, ‘-_‘, ‘+/‘);
            var b64DataNoSpaces = str.replace(/ /g,"+");
            b64DataNoSpaces = b64DataNoSpaces.replace(/-/g, "+")
            b64DataNoSpaces = b64DataNoSpaces.replace(/_/g, "/")
            var strData= atob(b64DataNoSpaces);
            var charData= strData.split('').map(function(x){return x.charCodeAt(0);});
            var binData= new Uint8Array(charData);
            var data = pako.inflateRaw(binData);
            return String.fromCharCode.apply(null, new Uint16Array(data));
        } catch(exception) {
            return false;
        }

        
    }
}

function decryptDataFromPhp(str){
    try {
        // $jsonData = strtr($jsonString, ‘-_‘, ‘+/‘);
        var b64DataNoSpaces = str.replace(/ /g,"+");
        b64DataNoSpaces = b64DataNoSpaces.replace(/-/g, "+")
        b64DataNoSpaces = b64DataNoSpaces.replace(/_/g, "/")
        var strData= atob(b64DataNoSpaces);
        var charData= strData.split('').map(function(x){return x.charCodeAt(0);});
        var binData= new Uint8Array(charData);
        var data = pako.inflateRaw(binData);
        return String.fromCharCode.apply(null, new Uint16Array(data));
    } catch(exception) {
        console.log(exception);
        return false;
    }
}  

function getTimestamp() {
var today = new Date();
var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
return date + ' ' + time;
}






export const onNewScanResultGuia = async (decodedText ) => {

    try {
        let coords = await obtenerCoordenadas()

        console.log(decodedText)

        let url = new URL(decodedText);
        let dataBase64 = url.searchParams.get('t')
        let s = url.searchParams.get('s')
        let jsonString = dataBase64.replace(/ /g,"+");

        if(s === 'app'){
            jsonString = decompressFromBase64(jsonString)
        }
        else {
            jsonString = decryptDataFromPhp(dataBase64);
        }

        let jsonData = JSON.parse(jsonString);
        let guia = {};
        let items = [];
        let location =   coords

        if(!jsonData){
            return { error : true }
        }

        if(jsonData.m_items.length > 0){
            for(let i=0; i < jsonData.m_items.length; i++){
                items.push({
                    'categoria_id' : jsonData.m_items[i].c,
                    'cantidad' : jsonData.m_items[i].q,
                    'material' : jsonData.m_items[i].d,
                    'precio' : jsonData.m_items[i].p
                });
            }
            guia.items = items;
        }

        guia.domain = url.hostname;
        guia.key = url.searchParams.get('k');
        guia.content = dataBase64;
        guia.numero = jsonData.e_num;
        guia.rut = jsonData.e_rut;
        guia._id = guia.rut + '-' + guia.numero;
        guia.received_at = getTimestamp();
        guia.received_coords = location;
        guia.completed = 1;
        guia.saved = 0;
        guia.delivered = 0;
        guia.fecha = jsonData.e_fecha
        guia.destino = jsonData.m_dest
        guia.pedido = jsonData.s_ped
        guia.entrega = jsonData.s_ent
        guia.ppu = jsonData.c_ppu
        guia.ccod = jsonData.c_cod
        guia.crut = jsonData.c_rut
        guia.mori = jsonData.m_ori
        guia.i = JSON.stringify(jsonData.m_items)
        guia.ga = jsonData.ga
        
        const doc = await db.guiaDespacho.get(jsonData.e_num);

        if( doc === undefined){
        return {guia:guia,ruta: '/carga/guia-scanned'}
        }
        else if( doc.guia.completed === 1 ){

        return {guia:guia,ruta: '/carga/documento-en-stock'}

        }else if(doc.guia.completed === 0){
        
        return {guia:guia,ruta: '/carga/guia-scanned-update'}

        }else{

        return {guia:guia,ruta: '/carga/guia-scanned'}

        }
    } catch (error) {
        console.log(error)
        
    }

}; 