import React, { useEffect, useState, useCallback } from 'react'
import Header from '../components/nav/Header'
import { useLiveQuery } from 'dexie-react-hooks'
import { db } from '../db/db'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import BottomMenu from '../components/nav/BottomMenu'

import { identificacion } from '../components/otros/textos'
import { sendSOData } from '../functions/patagoniaApi'
import { multiX, version } from '../components/otros/dominios'
import { browserName, mobileVendor, mobileModel, osVersion, osName, engineName, engineVersion } from 'react-device-detect';

const identidadPiloto = {
  idTransporte: "",
  rutPiloto: ""
}

// Unidades => camion, nave, bodega 
// Bodega => codigoSAP, rut
function formatRut(rut) {
  const rutLimpio = rut.replace(/[^0-9kK]/g, '');
  const cuerpo = rutLimpio.slice(0, -1);
  const dv = rutLimpio.slice(-1).toUpperCase();
  if (rutLimpio.length < 2) return rutLimpio;
  let cuerpoFormatoMiles = cuerpo
    .toString()
    .split('')
    .reverse()
    .join('')
    .replace(/(?=\d*\.?)(\d{3})/g, '$1.');
  cuerpoFormatoMiles = cuerpoFormatoMiles
    .split('')
    .reverse()
    .join('')
    .replace(/^[\.]/, '');
  return `${cuerpoFormatoMiles}-${dv}`;

}

function validateRut(rut) {


  const rutLimpio = rut.replace(/[^0-9kK]/g, '');
  if (rutLimpio.length < 2) return false;
  const cuerpo = rutLimpio.slice(0, -1);
  const dv = rutLimpio.slice(-1).toUpperCase();
  if (!cuerpo.replace(/[^0-9]/g, '')) return false;



  // Calculate verification digit
  let suma = 0;
  let multiplo = 2;
  for (let i = cuerpo.length - 1; i >= 0; i--) {
    suma += cuerpo.charAt(i) * multiplo;
    multiplo = (multiplo + 1) % 8 || 2;
  }
  const resultado = 11 - (suma % 11);
  const verificador = resultado === 11 ? '0' : resultado === 10 ? 'K' : resultado.toString();






  let errRut = true;
  const res = verificador === dv;
  if (rut && res === true) {
    errRut = false;
  }
  return errRut;

}


const Identificacion = () => {


  const [identidad, setIdentidad] = useState(identidadPiloto)
  const [identidad1, setIdentidad1] = useState([])
  const [selectedValue, setSelectedValue] = useState('terrestre');

  const ident = useLiveQuery(
    () => db.identidades
      .toCollection()
      .first()
      .then(firstElement => {
        setIdentidad({
          identidad,
          idTransporte: firstElement.idTransporte,
          rutPiloto: firstElement.rutPiloto
        })
      })
      .catch(error => {
        console.error(error);
      })

  );





  let navigate = useNavigate()

  const handleCheckboxChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const { idTransporte, rutPiloto } = identidad



  const onSubmit = async () => {

    console.log(validateRut(rutPiloto))
    if (idTransporte === '') return toast.error('No ha ingresado indentificación de transporte')
    if (rutPiloto === '') return toast.error('No ha ingresado indentificación de usuario')

    if (validateRut(rutPiloto) === false) {
      try {

        await db.identidades.clear();

        await db.identidades.add(identidad);


        const so = {
          version: osVersion,
          os: osName,
          marca: mobileVendor,
          modelo: mobileModel,
          navegador: browserName
        }

        let data = "key=" + multiX.key
          + "&tt_id=" + idTransporte
          + "&tt_rut=" + rutPiloto 
          + "&v=" + version 
          + "&timestamp=" + ''
          + "&so=" + JSON.stringify(so)

        console.log(data)

        sendSOData(data)
          .then(res => {
            console.log(res.data.error)
            if (res.data.error === false) {
              toast.success('Identidad guardada exitosamente')
              setIdentidad(identidadPiloto)
              if ('geolocation' in navigator) {
                navigator.permissions.query({ name: 'geolocation' })
                  .then(permissionStatus => {
                    if (permissionStatus.state === 'granted') {
                      navigate('/')
                    } else {
                      navigate('/gps')
                    }
                  })
                  .catch(error => {
                    console.error('Error checking geolocation permission:', error);
                    navigate('/gps')
                  });
              } else {
                console.log('Geolocation is not supported by this browser.');
                navigate('/gps')
              }
            } else {
              toast.success('No se ha podido notificar la información.')
            }

          })
          .catch(err => console.log(err))
        // toast.success("Identidad ingresada correctamente")



      } catch (error) {
        console.log('Fallo al añadir identidad')
      }
    } else {

      setIdentidad(identidadPiloto)

      toast.error("Rut Invalido")

    }


  }


  const handleChange = (e) => {
    setIdentidad({ ...identidad, [e.target.name]: e.target.value });
  }

  const handleKeyPress = (event) => {
    const charCode = event.which || event.keyCode;
    // Only allow alphanumeric characters (letters and numbers)
    if (charCode !== 8 && !/^[a-zA-Z0-9]+$/.test(String.fromCharCode(charCode))) {
      event.preventDefault();
    }
  };


  const handleRutChange = (e) => {
    let rut = formatRut(e.target.value)
    setIdentidad({ ...identidad, ['rutPiloto']: rut })
  }

  return (
    <>
      <Header />
      <div className='container p-4'>
        <h6 >
          <i class="fa-solid fa-user"></i> {identificacion.titulo}
        </h6>

        <hr />

        <p>{identificacion.subtitulo}</p>
        <br />
        <div className='row'>
          <div className='col-6'>
            <label><input type="checkbox" id="cbox1" value="terrestre" checked={selectedValue === 'terrestre'} onChange={handleCheckboxChange} /> {identificacion.checkbox1}</label>
          </div>
          <div className='col-6'>
            <label><input type="checkbox" id="cbox1" value="maritimo" checked={selectedValue === 'maritimo'} onChange={handleCheckboxChange} /> {identificacion.checkbox2}</label>
          </div>

          {/* <div className='col-4'>
                    <label><input type="checkbox" id="cbox1" value="bodega" checked={selectedValue === 'bodega'} onChange={handleCheckboxChange} /> {identificacion.checkbox3}</label>
                    </div> */}
        </div>


        <hr />

        <div className='row p-3' style={{ background: '#f1f1f1' }}>

          {selectedValue &&

            selectedValue === 'maritimo' && (
              <>
                <div className='col-6'>
                  <label> {identificacion.label_3}   </label>

                </div>
                <div className='col-6'>
                  <input type="text" name='idTransporte' required onChange={handleChange} onKeyPress={handleKeyPress} value={idTransporte.toUpperCase()} />

                </div>

                <div className='col-6 mt-3'>
                  <label>{identificacion.label_4}</label>

                </div>

                <div className='col-6 mt-3'>
                  <input type="text" name='rutPiloto' required onChange={e => handleRutChange(e)} value={rutPiloto} />

                </div>
                <br />

              </>
            )}



          {selectedValue &&

            selectedValue === 'terrestre' && (
              <>


                <div className='col-6'>
                  <label>{identificacion.label_1}</label>

                </div>
                <div className='col-6'>
                  <input type="text" name='idTransporte' required onChange={handleChange} onKeyPress={handleKeyPress} value={idTransporte.toUpperCase()} />

                </div>
                <div className='col-6 mt-3'>
                  <label>{identificacion.label_2}</label>

                </div>

                <div className='col-6 mt-3'>
                  <input type="text" name='rutPiloto' required onChange={e => handleRutChange(e)} value={formatRut(rutPiloto)} />

                </div>

              </>
            )
          }
          {selectedValue &&

            selectedValue === 'bodega' && (
              <>


                <div className='col-6'>
                  <label>{identificacion.label_5}</label>

                </div>
                <div className='col-6'>
                  <input type="text" name='idTransporte' required onChange={handleChange} onKeyPress={handleKeyPress} value={idTransporte.toUpperCase()} />

                </div>
                <div className='col-6 mt-3'>
                  <label>{identificacion.label_6}</label>

                </div>

                <div className='col-6 mt-3'>
                  <input type="text" name='rutPiloto' required onChange={e => handleRutChange(e)} value={formatRut(rutPiloto)} />

                </div>

              </>
            )
          }

        </div>
        <br />

        <button type="button" onClick={onSubmit} class="btn w-100 btn-primary mt-3"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-vcard" viewBox="0 0 16 16">
          <path d="M5 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm4-2.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5ZM9 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4A.5.5 0 0 1 9 8Zm1 2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5Z"></path>
          <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2ZM1 4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H8.96c.026-.163.04-.33.04-.5C9 10.567 7.21 9 5 9c-2.086 0-3.8 1.398-3.984 3.181A1.006 1.006 0 0 1 1 12V4Z"></path>
        </svg> {identificacion.boton}</button>

      </div>

      <BottomMenu />

    </>
  )
}

export default Identificacion